.video-ascii-panel {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  background-color: #000;
  overflow: hidden;
}

.video-ascii-holder {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-ascii-controller-holder {
  width: 100%;
  height: 10%;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo {
  width: 600px;
  height: 400px;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}