html {
  font-size: 20px;
  //overflow: hidden;
  
  cursor: url(https://r2.guns.lol/9cdc0575-0f7b-487a-a1e2-c50594fa81cc.cur) 16 16, auto !important;

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
}

body {
  margin: 0;
  padding: 0;
  background: black;
}
