//.app-title {
//  height: 35vh;
//  width: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  color: #ffffff;
//  margin: 0 0 0 0;
//}
//
//.video-input-container {
//  height: 65vh;
//  width: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//
//  //input {
//  //  width: 50%;
//  //  height: 5vh;
//  //  font-size: 1.5em;
//  //  border-radius: 0.5em;
//  //  border: 0.1em solid #ffffff;
//  //  padding: 0.5em;
//  //  color: #ffffff;
//  //  background: transparent;
//  //  outline: none;
//  //}
//
//  button {
//    //width: 10%;
//    //height: 5vh;
//    font-size: 1.5em;
//    border-radius: 0.5em;
//    border: 0.1em solid #ffffff;
//    padding: 0.5em;
//    color: #ffffff;
//    background: transparent;
//    outline: none;
//    font-style: italic;
//
//    &:hover {
//      background: #ffffff;
//      color: #000000;
//      cursor: pointer;
//    }
//  }
//}

@font-face {
  font-family: 'Array-Regular';
  src: url('./fonts/Array-Regular.woff2') format('woff2'),
       url('./fonts/Array-Regular.woff') format('woff'),
       url('./fonts/Array-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

.mode-selector-container {
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
}

.image-input-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;

  .image-settings {
    height: 55%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nophone {
    width: 0px;
    height: 0px;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  }

  .image-input-button {
    height: 45%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    button {
      background: transparent;
      color: #ffffff;
      border: none;
      font-size: 2rem;
      font-family: Array-Regular;
      font-weight: 100;
      cursor: url(https://r2.guns.lol/9cdc0575-0f7b-487a-a1e2-c50594fa81cc.cur) 16 16, auto !important;
      transition: all 0.2s ease-out;
      position: absolute;
    }

    button:hover {
      transform: scale(1.1)
  }
  }
}
